const form = [
  {
    name: '经度',
    type: 'inputNumber',
    key: 'longitude'
  },
  {
    name: '纬度',
    type: 'inputNumber',
    key: 'latitude'
  },
  {
    name: '打开地图缩放等级',
    type: 'inputNumber',
    key: 'openMapLevel'
  },
  {
    name: '村庄缩放等级',
    type: 'inputNumber',
    key: 'openVillageLevel'
  }
]
export default {
  type: 'form',
  url: '/config/farmConfigMap/detail',
  edit: '/config/farmConfigMap/save',
  data: form
}
